import i18nAPI from '../data/api/i18n-api-store.js'

export default class i18nService {
    static async index (language) {
        const namespaces = [
            'about',
            'browser',
            'common',
            'faq',
            'home',
            'how',
            'points',
            'installed',
            'order',
            'privacy',
            'processing',
            'checkout',
            'search',
            'support',
            'terms',
            'vertoflex',
        ]
        const languageData = {}
        for (const namespace of namespaces) {
            try {
                const response = await i18nAPI.show(namespace, language)
                languageData[namespace] = response
            } catch (error) {
                console.error('Error fetching i18n data -', error)
            }
        }
        return languageData
    }

    static async show (namespace, language) {
        try {
            return await i18nAPI.show(namespace, language)
        } catch (error) {
            console.error(`Error getting i18n data for namespace: ${namespace} and language: ${language}`)
            throw error
        }
    }
}
