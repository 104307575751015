export default (string_) => {
    // Remove whitespace and replace with dashes
    let url = string_.trim().replaceAll(/\s+/g, '-')

    // Remove any non-alphanumeric or non-dash characters
    url = url.replaceAll(/[^\dA-Za-z-]/g, '')

    url = url.toLowerCase()
    return url
}
