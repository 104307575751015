<template>
  <tmpl-default>
    <router-view />
  </tmpl-default>
</template>

<!-- <script src="./app.js"></script> -->

<style lang="scss">
@import './styles/main.scss';
</style>

<script>
import { onMounted } from 'vue'
import { useUserSessionStore } from './stores/user-session-store'

onMounted(() => {
    const userSession = useUserSessionStore()
    userSession.load()
})
</script>
