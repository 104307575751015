import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    actions: {
        clearUserData () {
            this.firstName = ''
            this.lastName = ''
            this.email = ''
        },

        getUserDate () {
            return {
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
            }
        },

        setUserData ({
            first_name,
            last_name,
            email,
        }) {
            this.firstName = first_name
            this.lastName = last_name
            this.email = email
        },
    },

    getters: {
        fullName: (state) => `${state.firstName} ${state.lastName}`, // Computed full name

        hasName: (state) => {
            return state.firstName.trim() !== '' || state.lastName.trim() !== ''
        },
    },

    state: () => ({
        email: null,
        firstName: null,
        lastName: null,
    }),
})
