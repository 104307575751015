<template>
  <div v-bind="$attrs" class="org-wizard">
    <div class="org-wizard__progress max-w-[640px]">
      <div class="org-wizard__progress-left">
        <h1 class="org-wizard__progress-left-header">{{ data.header }}</h1>
        <atm-item
          v-for="(item, key, index) in data.slides"
          :key="index"
          class="org-wizard__progress-left-item"
          v-bind:class="{ 'org-wizard__progress-left-item--selected': index === currentIndex }"
        >
          <h3 class="org-wizard__progress-left-paragraph" v-if="item.header">
            {{ data.paragraph }}
          </h3>
          <atm-img
            :src="currentImage"
            class="org-wizard__progress-left-image"
          />
          <div class="org-wizard__progress-left-item-header" v-if="item.header">
            {{ item.header }}
          </div>
          <div
            class="org-wizard__progress-left-item-paragraph"
            v-if="item.paragraph"
          >
            {{ item.paragraph }}
          </div>
          <atm-button
            v-if="item.button"
            class="org-wizard__progress-left-item-button"
            @click="nextSlide()"
          >{{ item.button }}</atm-button>
          <div
            class="org-wizard__progress-left-item-links"
            v-for="(link, linksKey, linksIndex) in item.links"
            :key="linksIndex"
          >
            <atm-button @click="href(link.href)">{{ link.copy }}</atm-button>
          </div>
        </atm-item>
      </div>
      <div class="org-wizard__progress-right">
        <atm-img :src="currentImage" class="org-wizard__progress-right-image" />
      </div>
    </div>
    <slot />
    <!-- <atm-item  class="org-wizard__navigation">
            {{currentIndex+1}}/3 <atm-button @click="previousSlide()">&lt;</atm-button><atm-button @click="nextSlide()">></atm-button>
        </atm-item> -->
  </div>
</template>

<script src="./org-wizard.js" />

<style lang="scss" src="./org-wizard.scss"></style>
