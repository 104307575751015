<template>
  <div v-bind="$attrs" class="org-accordion">
    <mlc-accordion-item
      v-for="(item, index) in items"
      :key="index"
      :title="item.title"
      :content="item.content"
      :isOpen="openItemId === item.title"
      @toggle="handleToggle(item.title)"
    />
  </div>
</template>

<script src="./org-accordion.js" />

<style lang="scss" src="./org-accordion.scss"></style>
