import UserSessionsAPI from '../data/api/user-session-api-store.js'

export default class UserSessionService {
    // Method to redirect user to login
    static async login (redirectUrl = '') {
        try {
            const parameters = redirectUrl ? redirectUrl : ''

            return await UserSessionsAPI.login(parameters) // Return the API response if needed
        } catch (error) {
            console.error('Error redirecting user to login -', error)
            throw error
        }
    }

    static async logout () {
        try {
            return await UserSessionsAPI.logout() // Return the API response if needed
        } catch (error) {
            console.error('Error redirecting user to logout -', error)
            throw error
        }
    }

    static async create (sessionData) {
        try {
            return await SessionsAPI.create(sessionData)
        } catch (error) {
            console.error('Error creating session -', error)
            throw error
        }
    }
}
