export default {
    component: () => import('./pg-support.vue'),
    meta: {
        accessControl: { public: true },
        title: 'Support - Jauntr',
    },
    name: 'support',
    path: '/support',
    redirect: '/faq/general',
}
