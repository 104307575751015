export default {
    emits: ['close', 'action'],
    methods: {
        closeModal () {
            this.$emit('close')
        },
    },
    props: {
        bodyText: {
            default: 'Default body content',
            type: String,
        },
        headerText: {
            default: 'Default Header',
            type: String,
        },
        visible: {
            default: false,
            type: Boolean,
        },
    },
}
