import BaseAPI from './base-api-store';

export default class UsersAPI extends BaseAPI {


    // Example method to fetch user details
    static async show () {
        try {
            const response = await BaseAPI.fetch('/user', {
                credentials: 'include',
                headers: { 'Access-Control-Allow-Credentials': true },
                method: 'GET'
            })

            if (!response.ok) {
                throw new Error('Failed to fetch user');
            }
            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error('Error fetching user', error);
            throw error;
        }
    }

    static async update (request) {
        try {
            const response = await BaseAPI.fetchSecure('/user', {
                body: request,
                credentials: 'include',
                method: 'PATCH'
            })

            if (!response.ok) {
                throw new Error('Failed to update user');
            }
            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error('Error updating user', error);
            throw error;
        }
    }

    static async delete () {
        try {
            const response = await BaseAPI.fetchSecure('/user', {
                credentials: 'include',
                method: 'DELETE'
            });

            if (!response.ok) {
                throw new Error('Failed to delete user');
            }

            return { message: 'User deleted successfully' };
        } catch (error) {
            console.error('Error deleting user', error);
            throw error;
        }
    }



}
