<template>
  <div class="tmpl-default">
    <mlc-navigation
      class="tmpl-default__navigation"
      @open-user-manage-modal="showUserManageModal = true"
      @log-out="logout"
      @log-in="login"
    >
      <template v-slot:logo>
        <router-link
          to="/"
          aria-label="Home"
          title="Home"
          class="cursor-pointer h-[24px] mx-auto sm:mx-0"
        >
          <atm-img
            :src="navigation.logo.src"
            :alt="navigation.logo.alt"
            class="cursor-pointer h-[24px]"
          />
          <span class="tmpl-default__navigation__crawler-only">Home</span>
        </router-link>
      </template>
      <template v-slot:menu>
        <mlc-list class="tmpl-default__navigation-list sm:flex hidden">
          <div v-for="(menu, menuIndex) in navigation.menu" :key="menuIndex">
            <router-link
              class="textNeutral2"
              :to="{ name: menu.name }"
              @click.native="goToHash(menu)"
              tabindex="0"
            >
              {{ menu.copy }}
            </router-link>
          </div>
        </mlc-list>
      </template>
      <template v-slot:action>
        <!-- add to browser btn -->
        <!-- <button v-if="route.path !== '/installed'" class="button-red"
					@click="href(navigation.action.href, true, true)">
					<div class="button-2">
						{{ navigation.action.copy }}
					</div>
				</button> -->

        <VDropdown
          :distance="6"
          placement="bottom-end"
          :disabled="!userSessionStore.auth"
          @show="dropdownOpen = true"
          @hide="dropdownOpen = false"
        >
          <button
            class="button outlined-neutral outlined-neutral-grey flex gap-1 hover:bg-grey-2"
            :class="{ 'bg-grey-2': dropdownOpen }"
            @click="toggleLoginModal()"
          >
            <i class="icon icon-account-filled" />
            <div class="button-2">
              {{
                userSessionStore.auth ? languageData.account : languageData.login
              }}
            </div>
          </button>

          <template #popper="{ hide }">
            <div
              class="w-[240px] rounded-xl divide-y z-[10000] relative bg-white"
            >
              <div class="flex flex-col flex-start self-stretch p-2">
                <div class="user-menu-points">
                  <div v-if="initials === 'null'" class="user-icon">
                    <img
                      src="/assets/icons/jauntr-plane-red.svg"
                      class="w-[58px]"
                    />
                  </div>
                  <atm-icon v-else :initials="initials"> </atm-icon>
                  <div class="text-center">
                    <div class="heading-3 !text-white">
                      {{ balance.toLocaleString() }} points
                    </div>
                    <div v-if="balance > 0" class="label !text-white">
                      {{ rewards }} or more in value
                    </div>
                    <div v-else class="label !text-white">
                      Book to start earning points!
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-2">
                <div
                  class="p-2 text-grey-9 hover:bg-grey-1 active:bg-grey-2 focus-visible:bg-grey-2 focus-visible:outline-none rounded-lg hover:cursor-pointer"
                  @click="navigateToPoints(hide)"
                  @keydown.enter="navigateToPoints(hide)"
                  tabindex="0"
                >
                  <div>Redeem Points</div>
                </div>
              </div>
              <div class="p-2">
                <div
                  v-for="item in languageData.userMenu"
                  @click="handleClick(item, hide)"
                  @keydown.enter="handleClick(item, hide)"
                  tabindex="0"
                  class="p-2 text-grey-9 hover:bg-grey-1 active:bg-grey-2 focus-visible:bg-grey-2 focus-visible:outline-none rounded-lg hover:cursor-pointer"
                >
                  <div>{{ item.label }}</div>
                </div>
              </div>
            </div>
          </template>
        </VDropdown>
      </template>
    </mlc-navigation>
    <div v-bind="$attrs" class="tmpl-default__body">
      <slot />
    </div>
    <div
      v-if="route.path !== '/installed'"
      class="tmpl-default__footer-wrapper"
    >
      <Footer :footerSections="footerSections" />
    </div>

    <VDropdown
      positioning-disabled
      :shown="showLoginModal || showUserManageModal"
      @show="openDropdown"
      @hide="closeDropdown"
    >
      <template #popper="{ hide }">
        <div
          class="w-screen h-screen bg-neutral-800 bg-opacity-25 flex justify-center items-center"
        >
          <div
            v-if="showLoginModal"
            class="w-[380px] p-6 bg-white relative rounded-xl shadow flex-col justify-center items-center"
          >
            <div class="w-full">
              <div class="h-[44px] w-[44px] absolute right-[9px] top-[9px]">
                <button
                  @click="toggleLoginModal(hide)"
                  class="focus-visible:ring-[2px] h-[44px] w-[44px] rounded-lg justify-center items-center flex textNeutral textNeutral-grey"
                >
                  <div class="h-6 w-6 icon icon-close-filled"></div>
                </button>
              </div>
            </div>

            <div class="flex items-center py-10 justify-center">
              <div class="flex items-center p-4 gap-3 bg-grey-0 rounded-[28px]">
                <div class="bg-JauntrGradient-500 rounded-lg">
                  <i class="icon icon-square icon-jauntr-square"></i>
                </div>

                <div class="w-6 h-6">
                  <i class="icon icon-arrow-switch text-grey-9"> </i>
                </div>

                <img class="" src="/assets/images/scrawlr-logo.svg" />
              </div>
            </div>

            <div class="flex flex-col gap-3 justify-center items-center mb-6">
              <div class="heading-3">
                {{ languageData.logInOrCreateAnAccount }}
              </div>

              <div class="text-center">
                <div class="body-1" v-html="languageData.jauntrPlatform" />

                <div class="body-1 !text-grey-7">
                  {{ languageData.accessAllScrawlr }}
                </div>
              </div>
            </div>

            <div class="w-full flex flex-col gap-3">
              <button
                class="h-[44px] button solid-primary solid-primary-blue w-full focus-visible:bg-blue-520 focus-visible:ring-[2px] focus-visible:ring-blue-300"
                @click="login()"
              >
                <span class="button-1 flex gap-1 justify-center items-center">
                  <i class="icon icon-scrawlr-circled !bg-white" />
                  {{ languageData.logInWithScrawlr }}
                </span>
              </button>

              <!-- <button class="h-[44px] button outlined-secondary focus:ring-[2px] focus:ring-blue-300 w-full"
								@click="href('https://auth.scrawlr.com/v1/signup')">
								<span class="button-1 justify-center items-center">
									{{ languageData.needAnAccountSignUp }}
								</span>
							</button> -->
            </div>
          </div>

          <div v-if="showUserManageModal" class="w-[400px] bg-white rounded-lg">
            <div
              class="w-full flex justify-between items-center"
              :class="userManageStep !== 1 ? 'pl-6 py-2 pr-2 ' : 'p-2'"
            >
              <div class="flex items-center gap-2">
                <div
                  v-show="userManageStep === 1"
                  class="h-[44px] w-[44px] right-[9px] top-[9px]"
                >
                  <button
                    @click="updateEditNameStep(0)"
                    class="h-[44px] w-[44px] rounded-lg justify-center items-center flex textNeutral textNeutral-grey focus-visible:ring-[2px]"
                  >
                    <div class="h-6 w-6 icon icon-arrow-tail-left"></div>
                  </button>
                </div>

                <div class="heading-3">
                  {{ getModalTitle }}
                </div>
              </div>

              <div class="h-[44px] w-[44px] right-[9px] top-[9px]">
                <button
                  @click="toggleUserManageModal(hide)"
                  autofocus
                  class="focus-visible:ring-[2px] h-[44px] w-[44px] rounded-lg justify-center items-center flex textNeutral textNeutral-grey"
                >
                  <div class="h-6 w-6 icon icon-close-filled" tabindex="1">
                  </div>
                </button>
              </div>
            </div>

            <div class="p-4 flex flex-col" v-if="userManageStep === 0">
              <div
                class="border border-grey-3 p-2 rounded-lg flex justify-between items-center mb-2"
              >
                <div class="px-1">
                  <div class="body-2 !text-grey-7">
                    {{ languageData.emailAddress }}
                  </div>
                  <span class="body-1 !text-grey-9">{{ userStore.email }}</span>
                </div>
              </div>

              <!-- edit name -->
              <!-- <div @click="updateEditNameStep(1)" @keydown.enter="updateEditNameStep(1)"
								class="hover:bg-grey-1 active:bg-grey-2 focus-visible:bg-grey-2 focus-visible:outline-none border border-grey-3 p-2 rounded-lg flex justify-between cursor-pointer items-center"
								tabindex="0">
								<div class="px-1">
									<div class="body-2 !text-grey-7">{{ languageData.name }}</div>
									<span v-if="userStore.hasName" class="body-1  ">{{ userStore.fullName }}</span>
									<span v-else="userStore.hasName" class="body-1 !text-grey-6 ">{{
										languageData.addYourName }}</span>
								</div>
								<i class="icon icon-arrow-rounded-right-outlined px-2 focus-visible:ring-[2px]" />

							</div> -->

              <!-- delete account button -->
              <!-- <div @click="updateEditNameStep(2)"
								class="hover:bg-grey-1 active:bg-grey-2 focus-visible:bg-grey-2 focus:ring-[2px] border border-grey-3 p-2 rounded-lg flex justify-between cursor-pointer mt-6"
								tabindex="0">
								<span class="px-2 body-1">{{ languageData.deleteAccount }}</span>
								<i class="icon icon-arrow-rounded-right-outlined px-2" />
							</div> -->
            </div>

            <!-- edit name step -->
            <div
              class="p-4 flex flex-col gap-8"
              v-if="userManageStep === 1"
              :key="resetKey"
            >
              <div class="flex gap-4">
                <div class="flex flex-col gap-1">
                  <div class="label pl-2">
                    {{ languageData.fname }}
                  </div>

                  <input
                    class="input focus:border focus:border-grey-7"
                    v-model="localUser.firstName"
                  />
                </div>

                <div class="flex flex-col gap-1">
                  <div class="label pl-2">
                    {{ languageData.lname }}
                  </div>

                  <input
                    class="input focus:border focus:border-grey-7"
                    v-model="localUser.lastName"
                  />
                </div>
              </div>

              <button
                @click="updateUserName"
                class="button button-2 solid-primary solid-primary-red focus-visible:ring-[2px]"
              >
                {{ languageData.saveChanges }}
              </button>
            </div>

            <div
              class="py-4 px-6 flex flex-col gap-8"
              v-if="userManageStep === 2"
            >
              <div class="gap-3 flex flex-col justify-start">
                <div
                  v-for="item in languageData.deleteAccountDetails"
                  class="text-grey-9 flex gap-2 items-start"
                >
                  <div class="justify-start items-start flex text-grey-9">
                    <i class="icon icon-alert-outlined !text-grey-8" />
                  </div>
                  <div>{{ item }}</div>
                </div>
              </div>

              <button
                @click="deleteAccount"
                class="button solid-primary !bg-[#D71616] hover:!bg-[#BD1313] active:!bg-[#B01212]"
              >
                <i
                  v-if="deletingAccount"
                  class="icon icon-loading-filled animate-spin !bg-white"
                ></i>
                <span v-else class="!text-white">{{
                    languageData.deleteAccount
                  }}
                </span>
              </button>
            </div>

            <div
              class="py-4 px-6 flex flex-col gap-8"
              v-if="userManageStep === 3"
            >
              <div class="body-1">{{ languageData.yourAccountDeleted }}</div>

              <button @click="hide()" class="button solid-neutral">
                <span class="button-1">{{ languageData.close }} </span>
              </button>
            </div>
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script src="./tmpl-default.js" />

<style lang="scss" src="./tmpl-default.scss"></style>
