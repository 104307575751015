// tracking.js
import { useGtag } from 'vue-gtag-next'
import { useRouter } from 'vue-router'
import environment from '../../.configs/app/environment'

export function useAnalytics () {
    const {
        event,
    } = useGtag()
    const router = useRouter()

    function trackEvent (eventName, eventParameters = {}, pageContext = {}) {
        try {
            let currentPath = router.currentRoute.value.path.slice(1).replaceAll('/', '_')
            if (currentPath === '') {
                currentPath = 'home'
            }
            if (currentPath === 'search' && pageContext.step) {
                if (pageContext.step <= 1) {
                    currentPath = 'search_departure'
                }
                if (pageContext.step === 2) {
                    currentPath = 'search_arrival'
                }
            }

            event(`${currentPath}_${eventName}`, {
                ...eventParameters,
                page_path: currentPath,
                send_to: environment.app.gaId,
            })
        } catch (error) {
            console.error('Error tracking event', error)
        }
    }

    return { trackEvent }
}

export function useAds () {
    const {
        event,
    } = useGtag()
    async function trackConversions (value, orderId) {
        try {
            event('conversion', {
                currency: 'CAD',
                // When using useGtag's events, it does not track conversions correctly
                send_to: `${environment.app.gaConversionId}/${environment.app.gaConversionLabel}`,
                transaction_id: await hash(orderId),
                value: value, // Price of trip
                // TODO: temp hash, need to actually anonymize according to GDPR
            })
        } catch (error) {
            console.error('Error tracking conversion', error)
        }
    }

    return { trackConversions }
}

async function hash (string) {
    const encoder = new TextEncoder()
    const data = encoder.encode(string)

    const hashBuffer = await crypto.subtle.digest('SHA-256', data)

    const hashArray = [...new Uint8Array(hashBuffer)]
    return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
}
