export default {
    children: [
        {
            component: () => import('./pg-faq.vue'),
            meta: {
                accessControl: { public: true },
                menuItem: 'general',
                title: 'General FAQ - Jauntr',
            },
            name: 'faq-general',
            path: 'general',
        },
        {
            component: () => import('./pg-faq.vue'),
            meta: {
                accessControl: { public: true },
                menuItem: 'bookings',
                title: 'Bookings FAQ - Jauntr',
            },
            name: 'faq-bookings',
            path: 'bookings',
        },
        {
            component: () => import('./pg-faq.vue'),
            meta: {
                accessControl: { public: true },
                menuItem: 'pricing',
                title: 'Pricing FAQ - Jauntr',
            },
            name: 'faq-pricing',
            path: 'pricing',
        },
        {
            component: () => import('./pg-faq.vue'),
            meta: {
                accessControl: { public: true },
                menuItem: 'offers',
                title: 'Offers FAQ - Jauntr',
            },
            name: 'faq-offers',
            path: 'offers',
        },
        {
            component: () => import('./pg-faq.vue'),
            meta: {
                accessControl: { public: true },
                menuItem: 'points',
                title: 'Points FAQ - Jauntr',
            },
            name: 'faq-points',
            path: 'points',
        },
    ],
    name: 'FAQ - Jauntr',
    path: '/faq',
}
