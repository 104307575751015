<template>
  <div
    class="w-full bg-grey-d100 flex-col justify-end items-center inline-flex"
  >
    <div
      class="w-full max-w-[1184px] lg:mx-auto self-stretch xs:justify-space p-8 flex-col sm:flex-row lg:flex-nowrap justify-start items-start gap-8 flex"
    >
      <div
        v-for="(section, sectionIndex) in footerSections"
        :key="sectionIndex"
        class="w-full gap-y-2 flex flex-col w-auto"
      >
        <div
          class="self-stretch w-full h-[29px] flex-col justify-center items-start gap-1 flex"
        >
          <div class="heading-5 !text-grey-d900">
            {{ section.title }}
          </div>
          <div class="w-full h-px relative bg-grey-d300"></div>
        </div>

        <div v-for="(link, linkIndex) in section.links" :key="linkIndex">
          <div class="button-2 !text-grey-d700">
            <router-link :to="{ name: link.name }">{{ link.text }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./pg-footer.js" />
