import * as atoms from './atoms'
import * as molecules from './molecules'
import * as organisms from './organisms'
import * as templates from './templates'

export default {
    ...atoms,
    ...molecules,
    ...organisms,
    ...templates,
}
