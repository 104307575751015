<template>
  <div v-bind="$attrs" class="org-split">
    <div class="org-split__left">
      <slot name="left" />
    </div>
    <div class="org-split__right">
      <slot name="right" />
    </div>
  </div>
</template>

<script src="./org-split.js" />
<style lang="scss" src="./org-split.scss"></style>
