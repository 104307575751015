import BaseAPI from './base-api-store';

export default class AirportsAPI extends BaseAPI {

    static async search (data) {
        try {
            const response = await BaseAPI.fetch('/airport', {
                body: data, method: 'post'
            });
            if (!response.ok) {
                throw new Error('Failed to fetch airports');
            }
            const responseData = await response.json();
            return responseData.data;
        } catch (error) {
            console.error('Error fetching airports');
            throw error;
        }
    }

}
