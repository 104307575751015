import AirportsAPI from '../data/api/airports-api-store'
export default class AirportService {
    static async searchAirports (body) {
        try {
            return AirportsAPI.search(body)
        } catch (error) {
            console.error('Error searching airports')
            throw error
        }
    }
}
