import environment from '../../../../.configs/app/environment.js'

export default {
    props: {
        content: String,
        isOpen: Boolean,
        title: String,
    },

    setup () {
        return { environment }
    },
}
