import FloatingVue, {
    Dropdown as VDropdown,
    options,
} from 'floating-vue'
import {
    computed,
    getCurrentInstance,
    inject,
    nextTick,
    onMounted,
    reactive,
    ref,
    watch,
} from 'vue'
import 'floating-vue/dist/style.css'
import { onClickOutside } from '@vueuse/core'
import flatpickr from 'flatpickr'
import { useSearchHistoryStore } from '../../../stores/search-history-store'
import { useAnalytics } from '../../../utilities/use-analytics'
import { useSearch } from '../../../utilities/use-search'

export default {
    emits: ['update:modelValue'],
    props: {
        modelValue: Object,
        type: {
            default: 'search',
            enum: ['search', 'verto'],
            type: String,
        },
    },
    setup (props, {
        emit,
    }) {
        const {
            airports,
            historyDeparture,
            historyArrival,
            localSearchAirport,
        } = useSearch()

        const trip = ref(props.modelValue)

        const {
            trackEvent,
        } = useAnalytics()
        const instance = getCurrentInstance()
        const app = instance.appContext.app

        const languageData = ref(inject('$languageData').value.home)

        const travellersTriggerButton = ref()

        app.component('v-dropdown', VDropdown)
        app.use(FloatingVue)

        const tripTypes = [
            {
                label: languageData.value.roundTrip,
                value: 'round',
            },
            {
                label: languageData.value.oneWay,
                value: 'one-way',
            },
        ]

        const tripOption = ref(tripTypes[0].label)

        const setTripType = (option) => {
            tripOption.value = option.label
            trip.value.tripType = option.value
            trackEvent('Set_TripType')
        }

        const addExtraPadding = () => {
            return trip.value.hasArrivalAirportError || trip.value.hasDepartureAirportError
                || trip.value.hasDepartureDateError
                || (isRound.value && trip.value.hasArrivalDateError)
        }

        const isRound = computed(() => {
            return trip.value.tripType === 'round'
        })

        const selectArrival = ref()
        const departureAirports = ref([])
        const arrivalAirports = ref([])
        const searchKeywordDeparture = ref()
        const searchKeywordArrival = ref()
        const departureDropdown = ref()
        const arrivalDropdown = ref()
        const searchHistoryStore = useSearchHistoryStore()

        const departurePicker = ref()
        const arrivalPicker = ref()

        const createSession = async () => {
            trackEvent('Click_SearchButton')

            trip.value.hasArrivalAirportError = checkError('arrival')
            trip.value.hasDepartureAirportError = checkError('depart')
            trip.value.hasDepartureDateError = checkError('departDate')

            if (isRound.value) {
                trip.value.hasArrivalDateError = checkError('arrivalDate')
            }

            if (isRound.value && trip.value.hasArrivalDateError) {
                return
            }

            if (trip.value.hasArrivalAirportError || trip.value.hasDepartureAirportError || trip.value.hasDepartureDateError) {
                return
            }

            const parmas = {
                adults: trip.value.travellers.adult,
                arrival: searchKeywordArrival.value,
                arrivalDate: trip.value.arrivalDate,
                children: trip.value.travellers.children,
                departure: searchKeywordDeparture.value,
                departureDate: trip.value.departureDate,
                ...(props.type === 'verto' && { searchType: 'verto' }),
            }

            if (isRound.value === false) {
                delete parmas.arrivalDate
            }

            appendToUrl(parmas)

            const currentUrl = window.location.href

            const url = new URL(currentUrl)

            url.pathname = '/search'

            window.open(url.toString(), '_self')

            searchHistoryStore.addSearchTerm(searchKeywordDeparture.value, 1)
            searchHistoryStore.addSearchTerm(searchKeywordArrival.value, 0)
        }

        const setLocation = async (departure, arrival, hide) => {
            if (departure) {
                searchKeywordDeparture.value = departure.city + ' (' + departure.iata + ')'
                toggleLocationDropdown(1, false)

                selectArrival.value.focus()
                trackEvent('Set_DepartureAirport')
            }

            if (arrival) {
                searchKeywordArrival.value = arrival.city + ' (' + arrival.iata + ')'
                toggleLocationDropdown(0, false)
                trackEvent('Set_ArrivalAirport')
            }

            if (hide) {
                hide()
            }
        }

        const switchLocation = () => {
            if (searchKeywordArrival.value || searchKeywordDeparture.value) {
                let temporary = ''
                temporary = searchKeywordArrival.value
                searchKeywordArrival.value = searchKeywordDeparture.value
                searchKeywordDeparture.value = temporary
            }
        }

        const moveToDropdown = (isDeparting) => {
            if (isDeparting) {
                departureDropdown.value.focus()
            } else {
                arrivalDropdown.value.focus()
            }
        }

        const selectFirst = (isDeparting) => {
            if (isDeparting && searchKeywordDeparture.value) {
                if (filteredHistoryDeparture.value[0]) {
                    setLocation(filteredHistoryDeparture.value[0])
                } else {
                    setLocation(departureAirports.value[0])
                }

                trackEvent('Set_DepartureAirport')
            }

            if (!isDeparting && searchKeywordArrival.value) {
                if (filteredHistoryArrival.value[0]) {
                    setLocation(null, filteredHistoryArrival.value[0])
                } else {
                    setLocation(null, arrivalAirports.value[0])
                }

                trackEvent('Set_ArrivalAirport')
            }
        }

        const checkLocationFormat = (input) => {
            const trimmedInput = input.trim()
            const cityIataPattern = /^[\sA-Za-z]+ \([A-Z]{3}\)$/

            // Check if input is already in "City (IATA)" format
            return cityIataPattern.test(trimmedInput)
        }

        const checkError = (input) => {
            if (input === 'depart') {
                return !searchKeywordDeparture.value || searchKeywordDeparture.value === searchKeywordArrival.value
                    || searchKeywordDeparture.value === '' || !checkLocationFormat(searchKeywordDeparture.value)
            }

            if (input === 'arrival') {
                return !searchKeywordArrival.value || searchKeywordDeparture.value === searchKeywordArrival.value
                    || searchKeywordArrival.value === '' || !checkLocationFormat(searchKeywordArrival.value)
            }

            if (input === 'departDate') {
                return !trip.value.departureDate
            }

            if (input === 'arrivalDate') {
                return !trip.value.arrivalDate
            }
        }

        const addTraveller = (isAdult, count) => {
            if (isAdult) {
                trip.value.travellers.adult += count
            } else {
                trip.value.travellers.children += count
            }
            trackEvent('Set_TravellerCount')
        }

        const pickers = reactive({})

        onMounted(async () => {
            await nextTick()

            const config = {
                altFormat: 'm/d',
                altInput: true,
                dateFormat: 'Y-m-d',
                disableMobile: 'true',
                minDate: 'today',
            }

            const parameters = extractFromUrl()

            pickers.departurePicker = flatpickr(departurePicker.value, {
                ...config,
                defaultDate: parameters.departureDate || '',
                onChange: function (selectedDates, dateString, instance) {
                    trackEvent('Set_DepartureDate')
                    if (pickers.arrivalPicker) {
                        const nextDay = new Date(selectedDates[0])
                        nextDay.setDate(selectedDates[0].getDate() + 1)
                        pickers.arrivalPicker.set('minDate', nextDay)
                        pickers.arrivalPicker.open()
                    }
                },
                onOpen: function (selectedDates, dateString, instance) {
                    instance.setDate(new Date().getFullYear())
                },
            })

            pickers.arrivalPicker = flatpickr(arrivalPicker.value, {
                ...config,
                defaultDate: parameters.arrivalDate || '',
                onChange: function (selectedDates, dateString, instance) {
                    trackEvent('Set_ArrivalDate')
                    if (pickers.departurePicker) {
                        const beforeDay = new Date(selectedDates[0])
                        beforeDay.setDate(selectedDates[0].getDate() - 1)
                        pickers.departurePicker.set('maxDate', beforeDay)
                        travellersTriggerButton.value.click()
                    }
                },
            })
        })

        const appendToUrl = (parameters) => {
            const url = new URL(window.location.href)
            url.search = ''
            for (
                const [
                    key,
                    value,
                ] of Object.entries(parameters)
            ) {
                if (value !== undefined && value !== null) {
                    url.searchParams.append(key, value.toString())
                }
            }
            window.history.pushState({}, '', url)
        }

        const extractFromUrl = () => {
            const urlParameters = new URLSearchParams(window.location.search)

            const parameters = {
                adults: urlParameters.get('adults'),
                arrival: urlParameters.get('arrival'),
                arrivalDate: urlParameters.get('arrivalDate'),
                children: urlParameters.get('children'),
                departure: urlParameters.get('departure'),
                departureDate: urlParameters.get('departureDate'),
                sessionId: urlParameters.get('sessionId'),
                step: urlParameters.get('step'),
            }

            if (parameters.departure) {
                searchKeywordDeparture.value = parameters.departure
            }
            if (parameters.arrival) {
                searchKeywordArrival.value = parameters.arrival
            }
            if (parameters.departureDate) {
                trip.value.departureDate = parameters.departureDate
            }
            if (parameters.arrivalDate) {
                trip.value.arrivalDate = parameters.arrivalDate
            }
            if (parameters.adults) {
                trip.value.travellers.adult = Number.parseInt(parameters.adults)
            }
            if (parameters.children) {
                trip.value.travellers.children = Number.parseInt(parameters.children)
            }
            if (parameters.departureDate && !parameters.arrivalDate) {
                trip.value.tripType = 'one-way'
            } else if (parameters.departureDate && parameters.arrivalDate) {
                trip.value.tripType = 'round'
            }

            if (trip.value.tripType === 'one-way') {
                setTripType(tripTypes[1])
            }

            return parameters
        }

        const debounce = (function_, delay) => {
            let timeoutId
            return (...arguments_) => {
                clearTimeout(timeoutId)
                timeoutId = setTimeout(() => {
                    function_(...arguments_)
                }, delay)
            }
        }

        const extractKeywords = (input) => {
            if (!input) return { iata: null }
            const regex = /^([\s\w]+)\s*\(?(\w*)\)?$/
            const match = input.match(regex)

            if (match) {
                return {
                    city: match[1].trim(),
                    iata: match[2].trim(),
                }
            }

            return {
                city: null,
                iata: null,
            }
        }

        const searchAirport = async (keyword, departing) => {
            if (!keyword) {
                return
            }
            const res = localSearchAirport(keyword, airports.value, 0, departing)

            if (departing) {
                departureAirports.value = res
            } else {
                arrivalAirports.value = res
            }
        }

        const debouncedSearchAirportDeparture = debounce((keyword) => searchAirport(keyword, 1), 200)
        const debouncedSearchAirportArrival = debounce((keyword) => searchAirport(keyword, 0), 200)

        watch(searchKeywordDeparture, debouncedSearchAirportDeparture)
        watch(searchKeywordArrival, debouncedSearchAirportArrival)

        const selectText = (event, isDeparting) => {
            event.target.select()

            toggleLocationDropdown(isDeparting, true)
        }

        const scrollToTop = () => {
            window.scrollTo({
                behavior: 'smooth',
                top: 0,
            })
        }

        const filteredHistoryDeparture = computed(() => {
            return searchKeywordDeparture.value
                ? localSearchAirport(searchKeywordDeparture.value, historyDeparture.value, 1, 1)
                : historyDeparture.value || []
        })

        const filteredHistoryArrival = computed(() => {
            return searchKeywordArrival.value
                ? localSearchAirport(searchKeywordArrival.value, historyArrival.value, 1, 0)
                : historyArrival.value || []
        })

        const resetScroll = (isDeparting) => {
            if (isDeparting) {
                showDepartureDropdown.value = true
            } else {
                showArrivalDropdown.value = true
            }

            if (isDeparting) {
                if (departureDropdown.value) {
                    departureDropdown.value.scrollTop = 0
                }
            } else {
                if (arrivalDropdown.value) {
                    arrivalDropdown.value.scrollTop = 0
                }
            }
        }

        const departureForm = ref()
        const arrivalForm = ref()

        const showDepartureDropdown = ref()
        const showArrivalDropdown = ref()

        const toggleLocationDropdown = (isDeparting, value) => {
            if (isDeparting) {
                showDepartureDropdown.value = value
            } else {
                showArrivalDropdown.value = value
            }
        }

        onClickOutside(departureForm, (event) => {
            toggleLocationDropdown(1, false)
        })

        onClickOutside(arrivalForm, (event) => {
            toggleLocationDropdown(0, false)
        })

        return {
            addExtraPadding,
            addExtraPadding,
            addTraveller,
            arrivalAirports,
            arrivalDropdown,
            arrivalForm,
            arrivalPicker,
            checkError,
            createSession,
            departureAirports,
            departureDropdown,
            departureForm,
            departurePicker,
            extractKeywords,
            filteredHistoryArrival,

            filteredHistoryDeparture,
            historyArrival,
            historyDeparture,

            // computed
            isRound,
            languageData,
            moveToDropdown,
            resetScroll,
            scrollToTop,
            searchAirport,
            searchKeywordArrival,

            searchKeywordDeparture,
            selectArrival,
            selectFirst,
            selectText,
            setLocation,

            // method
            setTripType,
            showArrivalDropdown,
            showDepartureDropdown,
            switchLocation,
            toggleLocationDropdown,
            travellersTriggerBtn: travellersTriggerButton,
            trip,
            tripOption,
            tripTypes,
            tripTypes,
        }
    },
}
