import PointsAPI from '../data/api/points-api-store'

export default class PointsService {
    // Redeem Points
    static async redeem (redeemData) {
        try {
            return await PointsAPI.redeem(redeemData)
        } catch (error) {
            console.error('Error redeeming points -', error)
            throw error
        }
    }

    // Show Points Balance
    static async show (balanceData) {
        try {
            return await PointsAPI.show(balanceData)
        } catch (error) {
            console.error('Error fetching points balance -', error)
            throw error
        }
    }

    // Display Points Balance (Logged In)
    static async display () {
        try {
            return await PointsAPI.display()
        } catch (error) {
            console.error('PointsAPI.display - Error:', error)
            throw error
        }
    }
}
