import BaseAPI from './base-api-store';

export default class PointsAPI extends BaseAPI {

    // Redeem Points
    static async redeem (redeemData) {
        try {
            const response = await BaseAPI.fetch('/point/redeem', {
                body: redeemData,
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                method: 'POST'
            });

            if (!response.ok) {
                const parsed = await response.json();
                throw new Error(parsed.message);
            }

            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error('Error redeeming points -', error);
            throw error;
        }
    }

    // Show Points Balance
    static async show (balanceData) {
        try {
            const response = await BaseAPI.fetch('/point/balance', {
                body: balanceData,
                headers: { 'Content-Type': 'application/json' },
                method: 'POST'
            });

            if (!response.ok) {
                const parsed = await response.json();
                throw new Error(parsed.message);
            }

            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error('Error fetching points balance -', error);
            throw error;
        }
    }

    // Display Points Balance (Logged In)
    static async display () {
        try {
            const response = await BaseAPI.fetch('/point', {
                credentials: 'include',
                headers: { 'Access-Control-Allow-Credentials': true },
                method: 'GET'
            })

            if (!response.ok) {
                throw new Error('Failed to fetch points');
            }
            const data = await response.json();
            return data.data;
        } catch (error) {
            console.error('Error fetching points', error);
            throw error;
        }
    }

}
