import environment from '../../../.configs/app/environment';
import { useUserSessionStore } from '../../stores/user-session-store';
class BaseAPI {

    static async fetch (endpoint, options = {}) {
        console.log('BaseAPI.fetch', environment, endpoint, options.body, environment.api.base);
        if (environment.api.base === 'mock') {
            console.log('BaseAPI.fetch API_MODE === mock', endpoint);
            return this.fetchMock(endpoint);
        }
        return fetch(environment.api.base + endpoint, {
            method: options.method,
            ...options.credentials && { credentials: options.credentials },
            body: JSON.stringify(options.body),
            headers: { ...options.body ? { 'Content-Type': 'application/json' } : {} },
            signal: options.signal
        });
    }

    static async fetchSecure (endpoint, options = {}) {

        console.log('BaseAPI.fetch', environment, endpoint, options.body, environment.api.base);

        const userSession = useUserSessionStore()
        const headers = {
            Authorization: 'Bearer ' + (userSession.getAuthToken()),
            ...options.headers || (options.body && { 'Content-Type': 'application/json' }),
        }
        try {
            return fetch(environment.api.base + endpoint, {
                method: options.method,
                ...options.credentials && { credentials: options.credentials },
                body: options.multipart ? options.body : JSON.stringify(options.body),
                headers
            })
        } catch {}
    }

    static async fetchMock (url) {
        console.log('BaseAPI.fetchMock');
        const [
            path,
            queryParameters
        ] = url.split('?');
        const mockFilePath = `/mocks/api${path.replaceAll(/[^\w./-]/g, '_')}_${queryParameters.replaceAll(/[^\w.-]/g, '_')}.js`;

        console.log('BaseAPI.fetchMock', mockFilePath);

        try {
            // const mockData = await import(mockFilePath);
            // console.log('BaseAPI.fetchMock', mockData);
            // return mockData.default;
        } catch (error) {
            console.error('BaseAPI.fetchMock Failed to load mock data:', error);
            throw error;
        }
    }

}

export default BaseAPI;
