import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import {
    createApp,
    ref,
} from 'vue'
import VueGtag, {
    trackRouter,
    useGtag,
} from 'vue-gtag-next'
import VueHotjar from 'vue-hotjar-next'
import environment from '../.configs/app/environment.js'
import App from './app.vue'
import components from './components'
import router from './routing/router.js'
import i18nService from './services/i18n-service.js'
import useBrowser from './utilities/use-browser.js'

import './styles/main.scss'

async function main () {
    const app = createApp(App)

    // -- Register components
    for (const component in components) {
        app.component(component, components[component])
    }

    const queryString = window.location.search
    const urlParameters = new URLSearchParams(queryString)
    const lang = urlParameters.get('language')

    const initialLanguage = lang || localStorage.getItem('language') || navigator.language.split('-')[0]
    if (!localStorage.getItem('language')) {
        localStorage.setItem('language', initialLanguage)
    }

    const language = ref(initialLanguage)
    const languageData = ref(await i18nService.index(language.value))

    app.provide('$language', language)
    app.provide('$languageData', languageData)

    const {
        browserInfo,
        supportsExtension,
    } = useBrowser()

    const extensionLabel = ref()
    const commonLanguageData = languageData.value.common
    if (supportsExtension) {
        if (browserInfo.name === 'Chromium' || browserInfo.name === 'Chrome') {
            extensionLabel.value = commonLanguageData.addToDefBrowser
        } else if (browserInfo.name === 'Microsoft Edge') {
            extensionLabel.value = commonLanguageData.addToBrowser.replace('{{browser}}', 'Edge')
        }
    } else {
        extensionLabel.value = commonLanguageData.getChromeExtension
    }

    app.provide('$extensionLabel', extensionLabel)

    app.provide('$tokenEx', TokenEx)
    app.use(router)

    app.use(VueGtag, {
        property: [
            {
                default: true,
                id: environment.app.gaId,
                params: { debug_mode: true },
            },
            {
                id: environment.app.gaConversionId,
                params: { debug_mode: true },
            },
        ],
    })

    app.use(VueHotjar, {
        id: environment.app.hotjarId,
        isProduction: true,
    })

    // trackRouter(router, {
    //     useScreenview: true
    // })

    Sentry.init({
        app,
        dsn: environment.app.sentryDsn,
        environment: environment.app.env,
        integrations: [],
        release: environment.app.sentryRelease,
    })

    const pinia = createPinia()
    app.use(pinia)
    app.mount('#app')
}

main()
